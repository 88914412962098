:root {
  --body-bg: #fff;
  --carrot: #ff815f;
  --dark-blue: #050635;
  --field-grey: #fbfbff;
  --green: #62e23b;
  --grey-for-text: #7e7f9b;
  --light-blue: #393875;
  --light-gray: #eaeaf7;
  --light-grey: #d3d4df;
  --orangish: #ff7279;
  --purple: #5843be;
  --sky-blue: #88dcff;
  --yellow: #ffb12b;
  --yellow-50: #fffbf4;

  /* BELOW FROM UI KIT */
  --font-weight-regular: 500;
  --font-weight-medium: 600;

  /* others */
  --color-white: #fff;
  --color-black: #000;
  --color-dark-blue: #0e0e2c;
  --color-magenta: #ff6982;
  --color-green: #3bac7d;
  --color-yellow: #ffb21e;
  --color-red: #e6202f;
  --color-text: var(--color-slate-900);

  /* WHITE LABELING THEMING VARIABLES */
  --color-primary: var(--color-purple-500);
  --color-primary-50: var(--color-purple-50);
  --color-primary-100: var(--color-purple-100);
  --color-primary-200: var(--color-purple-200);
  --color-primary-300: var(--color-purple-300);
  --color-primary-400: var(--color-purple-400);
  --color-primary-500: var(--color-purple-500);
  --color-primary-600: var(--color-purple-600);
  --color-primary-700: var(--color-purple-700);
  --color-primary-800: var(--color-purple-800);
  --color-primary-900: var(--color-purple-900);

  /* Colors which fall outside of theme */
  --color-breadcrumbs: #101042;
  --color-input-border: #8984ef;
  --color-modal-overlay: rgb(0 0 0 / 50%);
  --color-nav-bg: #171858;

  /* non-Mantine specific modals z-index */
  --modal-z-index: 200;
  --modal-z-offset: 10;
  --theme-font-family: "InterVariable", system-ui, sans-serif;
  --theme-login-bg: #050535;
  --theme-nav-bg: var(--color-nav-bg);
  --theme-welcome-bg: url("/auth/pm-welcome.webp") 40% 50% no-repeat;

  /* move out container size */
  --move-out-container-size: 1024px;
  --nav-header-height: 72px;
}
