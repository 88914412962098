@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Inter var";
    src: url("~inter-ui/variable-latin/InterVariable-subset.woff2")
      format("woff2");
  }

  html {
    @apply text-slate-900 font-inter;
  }

  body {
    @apply text-sm leading-standard bg-[--theme-login-bg];
  }

  html,
  body {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  @media screen and (width <= 600px) {
    html,
    body {
      overflow: auto !important;
    }
  }

  :root {
    /** purple */
    --color-purple-50: #f3f1fd;
    --color-purple-100: #dfdbfa;
    --color-purple-200: #bbb2f5;
    --color-purple-300: #9789f0;
    --color-purple-400: #7360eb;
    --color-purple-500: #5139e6;
    --color-purple-600: #351bd9;
    --color-purple-700: #2d17ba;
    --color-purple-800: #241395;
    --color-purple-900: #1b0e71;
    --color-purple-950: #170c5f;

    /* gray */
    --color-gray-50: #f9f9f9;
    --color-gray-100: #eee;
    --color-gray-200: #e0e0e0;
    --color-gray-300: #cdcdcd;
    --color-gray-400: #a3a3a3;
    --color-gray-500: #808080;
    --color-gray-600: #666;
    --color-gray-700: #4c4c4c;
    --color-gray-800: #333;
    --color-gray-900: #212121;
    --color-gray-950: #131313;

    /* slate */
    --color-slate-50: #f4f4f6;
    --color-slate-100: #e5e5eb;
    --color-slate-200: #cbccd7;
    --color-slate-300: #b1b2c3;
    --color-slate-400: #9898af;
    --color-slate-500: #7e7f9b;
    --color-slate-600: #62637e;
    --color-slate-700: #494a5f;
    --color-slate-800: #31313f;
    --color-slate-900: #0e0e2c;
    --color-slate-950: #0a0a1e;

    /* red */
    --color-red-50: #ffeff0;
    --color-red-100: #ffdde0;
    --color-red-200: #f9b7bc;
    --color-red-300: #f07a84;
    --color-red-400: #eb4c59;
    --color-red-500: #e6202f;
    --color-red-600: #ce1726;
    --color-red-700: #b71522;
    --color-red-800: #97111c;
    --color-red-900: #730d15;
    --color-red-950: #53090f;

    /* green */
    --color-green-50: #f0faf6;
    --color-green-100: #ddf3ea;
    --color-green-200: #bbe8d5;
    --color-green-300: #91d9bb;
    --color-green-400: #60c89d;
    --color-green-500: #3bac7d;
    --color-green-600: #359c71;
    --color-green-700: #2f8963;
    --color-green-800: #277253;
    --color-green-900: #1b503a;
    --color-green-950: #123527;

    /* yellow */
    --color-yellow-50: #fffaf0;
    --color-yellow-100: #fff3db;
    --color-yellow-200: #ffe9bd;
    --color-yellow-300: #ffdb94;
    --color-yellow-400: #fc6;
    --color-yellow-500: #ffba35;
    --color-yellow-600: #f5a300;
    --color-yellow-700: #d68f00;
    --color-yellow-800: #b37700;
    --color-yellow-900: #805500;
    --color-yellow-950: #614100;

    /* others */
    --color-white: #fff;
    --color-black: #000;
    --color-text: var(--color-slate-900);

    /* WHITE LABELING THEMING VARIABLES */
    --color-primary: var(--color-purple-500);
    --color-primary-50: var(--color-purple-50);
    --color-primary-100: var(--color-purple-100);
    --color-primary-200: var(--color-purple-200);
    --color-primary-300: var(--color-purple-300);
    --color-primary-400: var(--color-purple-400);
    --color-primary-500: var(--color-purple-500);
    --color-primary-600: var(--color-purple-600);
    --color-primary-700: var(--color-purple-700);
    --color-primary-800: var(--color-purple-800);
    --color-primary-900: var(--color-purple-900);
  }
}

@layer components {
  .text-1\.5xl {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  .text-xxs {
    font-size: 0.625rem;
    line-height: 1.6;
  }

  /* a highly specific line height based on a 14px font with 20px lines */
  .leading-standard {
    line-height: 1.43;
  }

  /* a highly specific width used for all levels of calendar */
  .w-63 {
    width: 15.75rem;
  }

  .skeleton {
    @apply absolute w-full h-full -translate-y-1/2 top-1/2 left-0 animate-pulse bg-slate-200;
  }

  .wildcard-primary {
    --color-wildcard-50: theme(colors.primary.50);
    --color-wildcard-100: theme(colors.primary.100);
    --color-wildcard-200: theme(colors.primary.200);
    --color-wildcard-300: theme(colors.primary.300);
    --color-wildcard-400: theme(colors.primary.400);
    --color-wildcard-500: theme(colors.primary.500);
    --color-wildcard-600: theme(colors.primary.600);
    --color-wildcard-700: theme(colors.primary.700);
    --color-wildcard-800: theme(colors.primary.800);
    --color-wildcard-900: theme(colors.primary.900);
  }

  .wildcard-red {
    --color-wildcard-50: theme(colors.red.50);
    --color-wildcard-100: theme(colors.red.100);
    --color-wildcard-200: theme(colors.red.200);
    --color-wildcard-300: theme(colors.red.300);
    --color-wildcard-400: theme(colors.red.400);
    --color-wildcard-500: theme(colors.red.500);
    --color-wildcard-600: theme(colors.red.600);
    --color-wildcard-700: theme(colors.red.700);
    --color-wildcard-800: theme(colors.red.800);
    --color-wildcard-900: theme(colors.red.900);
  }

  .wildcard-green {
    --color-wildcard-50: theme(colors.green.50);
    --color-wildcard-100: theme(colors.green.100);
    --color-wildcard-200: theme(colors.green.200);
    --color-wildcard-300: theme(colors.green.300);
    --color-wildcard-400: theme(colors.green.400);
    --color-wildcard-500: theme(colors.green.500);
    --color-wildcard-600: theme(colors.green.600);
    --color-wildcard-700: theme(colors.green.700);
    --color-wildcard-800: theme(colors.green.800);
    --color-wildcard-900: theme(colors.green.900);
  }

  .wildcard-slate {
    --color-wildcard-50: theme(colors.slate.50);
    --color-wildcard-100: theme(colors.slate.100);
    --color-wildcard-200: theme(colors.slate.200);
    --color-wildcard-300: theme(colors.slate.300);
    --color-wildcard-400: theme(colors.slate.400);
    --color-wildcard-500: theme(colors.slate.500);
    --color-wildcard-600: theme(colors.slate.600);
    --color-wildcard-700: theme(colors.slate.700);
    --color-wildcard-800: theme(colors.slate.800);
    --color-wildcard-900: theme(colors.slate.900);
  }
}
